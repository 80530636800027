<script>
import isEqual from 'lodash-es/isEqual';
import { createNamespacedHelpers } from 'vuex';
import { TextCellField } from '@/components/ui/tables';
import { createTablePatchPayload } from '../tableHelpers';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'InfluencerPriceNotesCell',
  components: { TextCellField },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    innerValue: '',
  }),
  computed: {
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    value() {
      return this.cell.value ?? '';
    },
  },
  watch: {
    value: {
      handler(v) {
        this.innerValue = v;
      },
      immediate: true,
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({ updateRow: 'updateRow' }),
    onRevert() {
      this.innerValue = this.value;
    },
    async onSubmit(e) {
      const prevValue = this.value;
      const nextValue = e.target.value;

      if (isEqual(prevValue, nextValue)) {
        return;
      }

      const payload = createTablePatchPayload(this.row.originalValue, {
        [this.currentColumn]: nextValue,
      });

      this.innerValue = nextValue;
      const isSubmitted = await this.updateRow(payload);
      !isSubmitted && this.onRevert();
    },
  },
};
</script>

<template>
  <TextCellField 
    :value="innerValue" 
    type="text"
    @revert="onRevert" 
    @submit="onSubmit" 
  >
    {{ innerValue }}
  </TextCellField>
</template> 