<script>
import { Icon } from '@iconify/vue2';

export default {
  name: 'InfluencerPopoverUploader',
  components: { Icon },
  props: {
    fileUpload: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
  },
  emits: ['submit'],
  data: () => ({
    attachments: [],
    inputValue: '',
  }),
  computed: {
    isMessageActive() {
      return this.inputValue || this.attachments.length > 0;
    },
    textareaHeight() {
      return this.inputValue ? '90px' : '40px';
    },
  },
  methods: {
    handleFileUpload(event) {
      const { files } = event.target;
      if (!files.length) return;
      this.attachments = [...files];
      event.target.value = '';
    },
    removeFile(index) {
      this.attachments.splice(index, 1);
    },
    submitData() {
      const data = {
        text: this.inputValue,
      };

      if (this.fileUpload && this.attachments.length > 0) {
        data.attachment = this.attachments[0];
      }
      this.$emit('submit', data);
      this.clearFields();
    },
    clearFields() {
      this.$nextTick(() => {
        this.inputValue = '';
        this.attachments = [];
      });
    },
  },
};
</script>

<template>
  <div class="notes-cell-field__footer">
    <button
      v-if="fileUpload"
      :disabled="disabled"
      class="notes-cell-field__icon"
      @click="$refs.fileInput.click()"
    >
      <Icon icon="ci:paperclip-attechment-tilt" width="24" />
    </button>
    <input ref="fileInput" style="display: none" type="file" @change="handleFileUpload">
    <div class="notes-cell-field__footer-input">
      <b-form-textarea
        ref="textarea"
        v-model="inputValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :style="{ height: textareaHeight }"
        autofocus
        class="notes-cell-field__textarea"
        @keydown.enter="submitData"
      />
      <div v-if="attachments.length" class="notes-cell-field__file">
        <div v-for="(file, index) in attachments" :key="index" class="notes-cell-field__file-item">
          <span>
            <Icon icon="ci:file-blank" style="margin-bottom: 2px" width="20" />
            {{ file.name }}
          </span>
          <button @click.stop="removeFile(index)">
            <Icon class="notes-cell-field__icon" icon="bx:x" width="20" />
          </button>
        </div>
      </div>
    </div>
    <button
      :class="['notes-cell-field__icon', { 'notes-cell-field__icon-active': isMessageActive }]"
      :disabled="!isMessageActive || disabled"
      @click="submitData"
    >
      <Icon icon="ci:paper-plane" width="24" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .notes-cell-field {
    &__footer {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      gap: 10px;

      &-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }

    &__icon {
      margin-top: 8px;
      cursor: pointer;
      color: colors.$secondary-light-grey;
      transition: color 0.3s ease;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &-active {
        color: colors.$brand-color;
      }
    }

    &__textarea {
      resize: none;
      transition: all 0.3s ease;
      background-color: colors.$primary-background;
      border: none;

      @include fonts.raleway-regular;
      @-moz-document url-prefix() {
        scrollbar-width: thin;
        scrollbar-color: #825af9 #f6f6f6;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f6f6f6;
        margin: 15px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e1d7ff;
        border-radius: 12px;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    &__file {
      background-color: colors.$primary-background;
      color: colors.$brand-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3px;
      padding: 0 15px 5px;

      @include fonts.raleway-semibold;

      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
</style>
