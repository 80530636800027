import Vue from 'vue';
import Vuex from 'vuex';

import ApiService from '@/api/ApiService';
import { INITIAL_BANNERS_VISIBILITY_STATE, LOCAL_STORAGE_KEYS } from '@/config';
import agencyCabinetModule from '@/store/modules/agencyCabinetModule';
import AISearchModule from '@/store/modules/AISearchModule';
import breakpointModule from '@/store/modules/breakpointModule';
import campaignModule from '@/store/modules/campaignModule';
import chatModule from '@/store/modules/chatModule';
import commentsModule from '@/store/modules/commentsModule';
import financeModule from '@/store/modules/financeModule';
import influencerPublicModule from '@/store/modules/influencerPublicModule';
import influencerSearchModule from '@/store/modules/influencerSearchModule';
import privacyPolicyModule from '@/store/modules/privacyPolicyModule';
import shopModule from '@/store/modules/shopModule';
import SidebarTutorialModule from '@/store/modules/sidebarTutorialModule';
import { UserRole } from '@/values/user';

import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    campaignModule,
    chatModule,
    financeModule,
    influencerPublicModule,
    influencerSearchModule,
    privacyPolicyModule,
    shopModule,
    breakpointModule,
    commentsModule,
    agencyCabinetModule,
    AISearchModule,
    SidebarTutorialModule,
  },

  state: {
    user: null,
    accountId: null,
    cardList: [],
    userAccountsList: [],
    accountsList: [],
    campaignList: [],
    campaignTotalCount: 0,
    campaignStatuses: [],
    campaign: null,
    campaignSettings: null,
    campaignDescription: null,
    campaignInfluencers: [],
    campaignInfluencersTotal: 0,
    campaignResults: [],
    campaignResultsTotal: 0,
    campaignBarters: [],
    campaignSelections: [],
    campaignSelectionsScore: null,
    interests: [],
    favouriteBloggers: [],
    geos: [],
    theme: 'red',
    // search filters
    searchCampaignName: null,
    searchCampaignDates: null,
    searchInfluencerName: null,
    bannersVisibilityState:
      JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEYS.bannersVisibilityState)) ||
      INITIAL_BANNERS_VISIBILITY_STATE,
  },
  getters: {
    isCurrentUserAdmin: (state) => Boolean(state.user?.is_admin),
    currentUser: (state) => state.user,
    currentUserRole: (state) => {
      const isAuthorized = localStorage.getItem(ApiService.ACCESS_TOKEN_LOCAL_STORAGE_KEY);
      const isAdmin = state.user?.is_admin;
      return !isAuthorized ? '' : isAdmin ? UserRole.Admin : UserRole.User;
    },
    isAIEnabled: (state) => {
      return Boolean(state.user.ai_enabled);
    },
    interests: (state) => state.interests,
    geos: (state) => state.geos,
    campaignDescription: (state) => state.campaignDescription,
  },
  mutations,
  actions,
});
