<script>
export default {
  name: 'BaseDivider',
  props: {
    color: { type: String, default: '#CFD4D9' },
    height: { type: String, default: '1px' },
    label: { type: String, default: '' },
  },
};
</script>

<template>
  <div class="divider-wrapper">
    <hr
      :style="{
        borderTop: `${height} solid ${color}`,
      }"
      class="divider"
    >
    <span v-if="label || $slots.default" :style="{ color }" class="divider-content">
      <slot>{{ label }}</slot>
    </span>
    <hr
      :style="{
        borderTop: `${height} solid ${color}`,
      }"
      class="divider"
    >
  </div>
</template>

<style lang="scss" scoped>
  .divider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .divider {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: none;
  }

  .divider-content {
    padding: 0 12px;
    white-space: nowrap;
  }
</style>
