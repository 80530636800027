<script>
import MarkdownIt from 'markdown-it';

import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import { modalService } from '@/services/modal.service';

export default {
  name: 'TutorialsContentModal',
  components: { BaseProvidedModal },
  props: {
    modalId: { type: String, required: true },
  },

  computed: {
    modalPayload() {
      return modalService().getState(this.modalId)?.payload ?? {};
    },
    parsedText() {
      const md = new MarkdownIt();
      const text = this.modalPayload.text;
      return text ? md.render(String(text)) : '';
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="modalId"
    :hide-header="false"
    :is-background-white="true"
    :title="modalPayload.title"
    size="xl"
  >
    <div v-html="parsedText" />
  </BaseProvidedModal>
</template>
