<script>
import { Icon } from '@iconify/vue2';

import { modalService } from '@/services/modal.service';
import colors from '@/style/colors';
import { ModalName } from '@/values/modalName';

export default {
  name: 'TutorialsPopoverContent',
  components: { Icon },
  props: {
    video: { type: Array | Object, required: true },
    tutorials: { type: Array, required: true },
  },
  emits: ['close:tutorial', 'change:video'],
  data: () => ({
    isVideoPlaying: false,
    colors: colors,
  }),
  computed: {
    ModalName() {
      return ModalName;
    },
  },
  watch: {
    video() {
      this.isVideoPlaying = false;
    },
  },
  methods: { modalService },
};
</script>

<template>
  <div class="tutorials-content">
    <header class="tutorials-content__header">
      <translate>Quick start</translate>
      <button @click="$emit('close:tutorial')">
        <Icon class="tutorials-content__icon" icon="bx:x" width="30" />
      </button>
    </header>

    <hr class="tutorials-content__divider">
    <div class="tutorials-content__video-container">
      <div>
        <div
          v-if="!isVideoPlaying && (video.youtube_video_id || video.video_link)"
          class="tutorials-content__video-poster"
        >
          <img
            :src="video.image_thumbnail"
            alt=""
            class="tutorials-content__video-poster__content"
          >

          <button
            class="tutorials-content__video-poster__button"
            @click.stop="isVideoPlaying = !isVideoPlaying"
          >
            <img
              :src="require('@/assets/play.png')"
              alt="Play"
              class="tutorials-content__video-poster__play-icon"
            >
          </button>
        </div>

        <iframe
          v-if="isVideoPlaying && video.youtube_video_id"
          :src="`https://www.youtube.com/embed/${video.youtube_video_id}`"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          class="tutorials-content__video"
          frameborder="0"
          referrerpolicy="strict-origin-when-cross-origin"
          title="YouTube video player"
        />
      </div>
      <span class="tutorials-content__video-title">
        <translate>
          {{ video.title }}
        </translate>
      </span>
      <button
        v-if="video.description"
        class="tutorials-content__text-container"
        @click="
          modalService().open(ModalName.TutorialsContentModal, {
            title: video.title,
            text: video.description,
          })
        "
      >
        <translate>Read more</translate>
        <Icon class="content-link__icon" icon="bx-link-external" />
      </button>
    </div>
    <ul class="tutorials-content__cards">
      <li
        v-for="tutorial in tutorials"
        :key="tutorial.step"
        :class="tutorial.step === video.step ? 'tutorials-content__card--active' : ''"
        class="tutorials-content__card"
        @click.stop="$emit('change:video', tutorial.step)"
      >
        <img :src="tutorial.image_thumbnail" alt="video" class="tutorials-content__card-video">
        <div class="tutorials-content__card-info">
          <span class="tutorials-content__card-time">{{ tutorial.duration }}</span>
          <span class="tutorials-content__card-description">
            {{ tutorial.title }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .tutorials-content {
    color: colors.$primary-black;
    display: flex;
    flex-direction: column;
    max-height: min(600px, 60vh);
    overflow-y: auto;
    width: min(420px, 80vw);

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      padding: 10px 20px;
      @include fonts.raleway-bold;
    }

    &__icon {
      color: colors.$secondary-light-grey;
      cursor: pointer;
    }

    &__divider {
      border: none;
      border-top: 1px solid colors.$secondary-white-grey;
      height: 0;
      margin: 0;
      padding: 0;
    }

    &__text-container {
      display: flex;
      gap: 5px;
      @include fonts.fontSize-small;
      align-items: center;
      color: colors.$primary-blue;
      @include fonts.raleway-semibold;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
        text-decoration: underline;
      }
    }

    &__video-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 30px;
    }

    &__video-poster {
      position: relative;
      overflow: hidden;
      height: 160px;

      &__content {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }

      &__button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        border: none;
        border-radius: 50%;
        cursor: pointer;
      }

      &__play-icon {
        width: 32px;
        height: auto;
      }
    }

    &__video {
      border: 1px solid colors.$primary-background-dark;
      border-radius: 8px;
      width: 100%;
      height: 100%;

      &-title {
        display: -webkit-box;
        line-clamp: 2;
        overflow: hidden;
        overflow-wrap: break-word;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
      }
    }

    &__cards {
      color: colors.$secondary-grey;
      display: flex;
      flex-direction: column;
      margin: 0 5px;
      overflow-y: auto;
      padding: 0 15px;
      @include fonts.fontSize-normal;
      @include fonts.raleway-medium;

      @-moz-document url-prefix() {
        scrollbar-color: #e1d7ff #f6f6f6;
        scrollbar-width: thin;
      }

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e1d7ff;
        border-radius: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #f6f6f6;
        margin: 15px 0;
      }
    }

    &__card {
      align-items: start;
      background-color: transparent;
      border-radius: 10px;
      color: colors.$secondary-grey;
      cursor: pointer;
      display: flex;
      gap: 15px;
      padding: 10px;
      transition: background-color 0.2s ease;

      &--active {
        background-color: colors.$primary-background;
      }

      &:hover {
        background-color: colors.$primary-background;

        .tutorials-content__card-video {
          border: 1px solid #e1d7ff;
        }
      }

      &-description {
        display: -webkit-box;
        line-clamp: 3;
        overflow: hidden;
        overflow-wrap: break-word;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        white-space: normal;
      }

      &-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: hidden;
      }

      &-time {
        color: colors.$primary-grey-light;
        @include fonts.fontSize-small;
        @include fonts.raleway-regular;
      }

      &-video {
        border: 1px solid colors.$primary-background-dark;
        border-radius: 8px;
        height: 80px;
        width: 120px;
      }
    }
  }
</style>
