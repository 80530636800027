import ApiService from '@/api/ApiService';
import { endpoints } from '@/api/endpoints';
import { WaitState } from '@/values/waitState';

const api = new ApiService();
export default {
  namespaced: true,
  state: () => ({
    chat: {
      messages: [],
      complete: false,
      filters: [],
      results: {},
    },
    defaultChat: {
      messages: [],
      complete: false,
      filters: [],
      results: {},
    },
    waitState: WaitState.default,
  }),
  mutations: {
    setWaitState(state, stateStatus) {
      state.waitState = stateStatus;
    },
    setDefaultChat(state, options) {
      state.defaultChat = { ...state.defaultChat, ...options };
    },
    setOptionsChat(state, options) {
      state.chat = { ...state.chat, ...options };
    },
    updateChat(state, newChat) {
      state.chat = newChat;
    },
    addMessage(state, message) {
      state.chat.messages.push(message);
    },
    clearChat(state) {
      state.chat = state.defaultChat;
    },
  },
  actions: {
    changeOptionsChat: async ({ commit }, payload) => {
      try {
        commit('setWaitState', WaitState.isLoading);
        const { data } = await api.post(`${endpoints.search_bloggers.ai}`, payload);
        commit('setWaitState', WaitState.default);
        commit('updateChat', data);
      } catch {
        commit('setWaitState', WaitState.isFailure);
      }
    },
    fetchFirstMessage: async ({ dispatch, commit, state }, payload) => {
      commit('setDefaultChat', payload);
      await dispatch('changeOptionsChat', state.defaultChat);
    },
    sendMessage: async ({ state, commit, dispatch }, { text }) => {
      commit('addMessage', { text: text, sender: 'user' });
      await dispatch('changeOptionsChat', state.chat);
    },
    addTotalResult: async ({ dispatch, commit, state }, payload) => {
      commit('setOptionsChat', payload);
      await dispatch('changeOptionsChat', state.chat);
    },
    resetChat: async ({ state, dispatch }) => {
      await dispatch('changeOptionsChat', state.defaultChat);
    },
  },
  getters: {
    chat: (state) => state.chat ?? [],
    waitState: (state) => state.waitState ?? WaitState.default,
  },
};
