import moment from 'moment';

import { getLanguage } from '@/services/locale.service';
import { videoExtensions } from '@/values/mediaExtensions';
import { NetworkType } from '@/values/network';

/**
 * The function of matching a value using a regular expression with localized text
 * @param value {string} - text for normalization
 * @param matcher {{[key: string]: string}} - Key: RegExpString, Value: Localized string
 * @returns {string}
 */
export function normalizeByLocale(value, matcher) {
  let text = String(toNonUndefined(value, ''));

  if (!text) {
    return '';
  }

  for (const [regexp, localizedText] of Object.entries(matcher)) {
    if (new RegExp(regexp).test(text)) {
      text = localizedText;
      break;
    }
  }

  return text;
}

export function normalizeString(value) {
  if (typeof value === 'symbol') {
    return value.description || '';
  }

  if (typeof value === 'number') {
    return Number.isNaN(value) ? '' : String(value);
  }

  return value || '';
}

export const toNumber = (value, defaultValue = 0) => {
  if (typeof value === 'object') return defaultValue;
  if (typeof value === 'function') return defaultValue;
  if (typeof value === 'boolean') return defaultValue;
  if (Number.isNaN(Number(value))) return defaultValue;
  return Number(value);
};

export const toArray = (value) => {
  return Array.isArray(value) ? value : [];
};

export const toNumberString = (n, postfix = '', separator = false) => {
  if (!n) return '-';
  const value = Intl.NumberFormat(getLanguage()).format(n);
  const space = separator ? ' ' : '';
  return postfix ? `${value}${space}${postfix}` : `${value}`;
};

export const toFloatString = (n, digits = 2, postfix = '') => {
  if (!n || isNaN(Number(n))) return '-';
  const value = Number(parseFloat(n).toFixed(digits));
  return postfix ? `${value}${postfix}` : `${value}`;
};

export const toDate = (dateStr) => {
  const [year, month, day] = dateStr.split('-');
  return new Date(year, month - 1, day);
};

export function toDateString(date) {
  return date ? Intl.DateTimeFormat(getLanguage()).format(toDate(date)) : '';
}

export function toDateTimeString(value) {
  return value ? moment(value).format('HH:mm DD.MM.YYYY') : '';
}

export function toDateFirstDateTimeString(value) {
  return value ? moment(value).format('DD.MM.YYYY HH:mm') : '';
}

export function toTimeString(value) {
  return value ? moment(value).format('HH:mm') : '';
}

export function toCapitalize(v) {
  const text = normalizeString(v);
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
}

export function toNonUndefined(value, defaultValue = '') {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  return value;
}

export function inputOnlyNumber(e) {
  const allowedKeys = [
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
    'Escape',
    'Enter',
    '.',
    ',',
  ];
  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
  if (e.key === '.') {
    const value = e.target.value;
    if (value.includes('.')) {
      e.preventDefault();
    }
  }
  if (e.key === 'e' || e.key === 'E') {
    e.preventDefault();
  }
}

export function normalizeNetworkHref(type, href) {
  if (!href) return '';
  if (/^http/.test(href ?? '')) return href;

  switch (type) {
    case NetworkType.Instagram: {
      if (href.includes('instagram.com')) {
        return `https://${href}`;
      }
      return `https://www.instagram.com/${href}`;
    }
    case NetworkType.Tiktok: {
      if (href.includes('tiktok.com')) {
        return `https://${href}`;
      }
      return `https://www.tiktok.com/${href.includes('@') ? href : `@${href}`}`;
    }
    case NetworkType.Youtube: {
      if (href.includes('youtube.com')) {
        return `https://${href}`;
      }
      return `https://www.youtube.com/${href.includes('@') ? href : `@${href}`}`;
    }
    default: {
      return href;
    }
  }
}

export function normalizeMediaContent(media) {
  const extension = media.name.slice(media.name.lastIndexOf('.')).toLowerCase();

  if (videoExtensions.has(extension)) {
    const attachmentTypes = [...videoExtensions].reduce((acc, el) => {
      const extensionCode = el.slice(1, el.length);
      acc[extensionCode] = media.attachment;
      return acc;
    }, {});

    media.video = {
      ...attachmentTypes,
      props: {
        autoplay: false,
        loop: false,
        controls: false,
        muted: true,
      },
    };
  } else {
    media.video = null;
  }

  return media;
}

export function removeEmptyFields(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (typeof value === 'string' && !value.trim()) return false;
      if (Array.isArray(value))
        return (
          value.length &&
          value.some((item) =>
            typeof item === 'object' ? Object.keys(removeEmptyFields(item)).length : item,
          )
        );
      if (typeof value === 'object' && value !== null)
        return Object.keys(removeEmptyFields(value)).length;
      return true;
    }),
  );
}
