import ApiService from '@/api/ApiService';
import { endpoints } from '@/api/endpoints';
import { LOCAL_STORAGE_KEYS } from '@/config';
import { buildGetParams } from '@/functions/buildGetParams';
import { createStorage } from '@/functions/createStorage';

const api = new ApiService();

const hidingTutorialsStorage = createStorage(LOCAL_STORAGE_KEYS.tutorialsHidingState, {});

export default {
  namespaced: true,
  state: () => ({
    tutorials: [],
    hidingState: hidingTutorialsStorage.getValue(),
  }),
  mutations: {
    setTutorials(state, newState) {
      state.tutorials = newState;
    },
    setHidingState(state, newRoute) {
      const newState = { ...state.hidingState, [newRoute]: true };
      state.hidingState = newState;

      hidingTutorialsStorage.setValue(newState);
    },
  },
  actions: {
    fetchTutorials: async ({ commit }, { route }) => {
      const query = buildGetParams({ page_url: `${route.path}` });
      const { data } = await api.get(`${endpoints.campaign.tutorials}?${query}`);
      commit('setTutorials', data.steps);
    },
  },
  getters: {
    tutorials: (state) => state.tutorials ?? [],
    hidingState: (state) => state.hidingState ?? {},
  },
};
