import { render, staticRenderFns } from "./TutorialsPopover.vue?vue&type=template&id=52701729&scoped=true"
import script from "./TutorialsPopover.vue?vue&type=script&lang=js"
export * from "./TutorialsPopover.vue?vue&type=script&lang=js"
import style0 from "./TutorialsPopover.vue?vue&type=style&index=0&id=52701729&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52701729",
  null
  
)

export default component.exports