<script>
import { Icon } from '@iconify/vue2';
import { createNamespacedHelpers } from 'vuex';

import TutorialsPopoverContent from '@/components/tutorials/TutorialsPopoverContent.vue';

const TutorialsStore = createNamespacedHelpers('SidebarTutorialModule');
export default {
  name: 'TutorialsPopover',
  components: { TutorialsPopoverContent, Icon },
  props: {
    isCollapsed: { type: Boolean, default: false },
  },
  data: () => ({
    isDropdownOpen: false,
    currentVideo: [],
    allVideos: [],
  }),
  computed: {
    ...TutorialsStore.mapGetters({
      tutorials: 'tutorials',
      hidingState: 'hidingState',
    }),
  },
  watch: {
    tutorials: {
      handler(newVal) {
        if (newVal.length) {
          this.currentVideo = newVal[0];
          this.allVideos = newVal;

          this.$nextTick(() => {
            if (!this.hidingState[this.$route.path]) this.showDropdown();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...TutorialsStore.mapMutations({
      setHidingState: 'setHidingState',
    }),

    showDropdown() {
      this.isDropdownOpen = true;
      this.$refs.dropdown.show();
    },
    hideDropdown() {
      this.isDropdownOpen = false;
      this.$refs.dropdown.hide();
      this.setHidingState(this.$route.path);
    },

    handleChangeTutorial(selectedId) {
      this.currentVideo = this.allVideos.find((t) => t.step === selectedId);
    },
  },
};
</script>

<template>
  <b-dropdown
    v-if="tutorials.length"
    ref="dropdown"
    class="dropdown-tutorials"
    dropright
    no-caret
    variant="none"
    @hide="hideDropdown"
    @show="showDropdown"
  >
    <template #button-content>
      <div class="dropdown-tutorials__header">
        <Icon icon="iconamoon:lightning-1-thin" width="20" />
        <translate v-if="!isCollapsed">
          Quick start
        </translate>
      </div>
      <Icon
        v-if="!isCollapsed"
        :class="['dropdown-tutorials__icon', { 'dropdown-tutorials__icon--open': isDropdownOpen }]"
        icon="ci:caret-down-md"
      />
    </template>
    <TutorialsPopoverContent
      :tutorials="allVideos"
      :video="currentVideo"
      @change:video="handleChangeTutorial"
      @close:tutorial="hideDropdown"
    />
  </b-dropdown>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .dropdown-tutorials {
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      background-color: colors.$primary-lavender;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 10px;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        @include breakpoints.for-big-desktop-up {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__icon {
      margin-left: auto;
      color: colors.$primary-grey;
      transition: transform 0.3s ease;
      width: 18px;
      height: 18px;

      &--open {
        transform: rotate(-90deg);
      }
    }

    &:deep(.btn) {
      @include fonts.raleway-semibold;
      @include fonts.fontSize-normal;
      max-width: 100%;
      color: colors.$brand-color;
      gap: 10px;
      display: flex;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border-radius: 16px;
      cursor: pointer;
      flex: 1;
      white-space: nowrap;

      @include breakpoints.for-big-desktop-up {
        height: 48px;
        @include fonts.fontSize-big;
      }

      & span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:active {
        border-color: transparent;
      }
    }

    &:deep(.dropdown-menu) {
      margin-left: 15px;
      margin-bottom: 100px;
      background-color: colors.$primary-white;
      box-shadow: 1px 4px 24px 0 #302a6f1a;
      border: none;
      border-radius: 16px;
    }
  }
</style>
